import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { SEO } from '../components/seo';
import Layout from '../components/layout';
import { dots, specialContent } from '../components/layout.module.css';
import dotImage from '../images/dots-light.png';

// Basic template for basic pages

function Page({ pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
  };
  const slug = pageContext?.fields?.slug?.replace('/', '')?.toLowerCase() ?? '';
  return (
    <div className={slug}>
      <Layout meta={meta} pageTitle={meta.title}>
        <div className={specialContent}>
          <MDXRenderer>{page.body}</MDXRenderer>
        </div>
        <img className={dots} src={dotImage} alt="dots" />
      </Layout>
    </div>
  );
}

export default Page;

export const Head = (pageContext) => <SEO pageContext={pageContext} />;
